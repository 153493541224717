@use '../vars'

=pageTitle
	font-weight: 900
	font-size: 60px
	line-height: 65px
	@media (min-width: 425px)
		font-size: 72px
		line-height: 75px
	@media (min-width: 768px)
		font-size: 100px
		line-height: 120px
	@media (min-width: 1024px)
		font-size: 120px
		line-height: 140px
	@media (min-width: 1200px)
		font-size: 144px
		line-height: 200px

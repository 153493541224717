
@use 'common'
@use 'sass:math'

.CardMedia
	margin: 2rem 0
	@media (min-width: 768px)
		display: flex

.Box
	@media (min-width: 768px)
		width: math.div(100%, 2)

.ImageWrapper
	max-width: 668px

.ImageNote
	+common.infoNote
	font-style: italic
	font-weight: normal

.Text
	+common.openingText
	margin-top: 1rem
	@media (min-width: 768px)
		padding-left: 1rem
		margin-top: 0
	@media (min-width: 1200px)
		padding-left: 2rem

@use '../vars'

=openingText
	font-size: 24px
	line-height: 30px
	@media (min-width: 1024px)
		font-size: 30px
		line-height: 38px
	@media (min-width: 1200px)
		font-size: 36px
		line-height: 50px

@use 'common'
@use 'sass:math'

.Events
	display: flex
	flex-wrap: wrap
	padding: 0.5rem
	@media (min-width: 768px)
		padding: 1rem

.Event
	width: math.div(100%, 2)
	padding: 1.25rem 0.5rem
	&:nth-child(even)
		padding-top: 2.5rem

	@media (min-width: 768px)
		padding: 2.25rem 1rem
		width: math.div(100%, 3)
	@media (min-width: 1024px)
		width: math.div(100%, 4)

.Link
	text-decoration: none

.ImageWrapper
	position: relative
	padding-top: math.div(100%, math.div(158, 197))
	overflow: hidden
	@media (min-width: 768px)
		padding-top: math.div(100%, math.div(328, 344))

.Image
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	overflow: hidden
	transition: 0.5s ease-in-out
	&:hover
		transform: scale(1.05)

.Date
	+common.infoNote
	padding: 5px 0 11px
	line-height: 17px
	@media (min-width: 768px)
		padding: 11px 0
.Title
	+common.text
	font-weight: 900

.EventLabels
	display: flex
	flex-wrap: wrap
	margin-left: -0.5rem
	margin-top: 1rem

.EventLabel
	background: black
	color: white
	margin: 0.25rem
	padding: 0.3rem 0.5rem 0.4rem
	border-radius: 10px

@use 'common'

.TimelinePage
	display: flex
	flex-direction: column
	.Title
		+common.pageTitle
		padding: 1rem
		@media (min-width: 768px)
			padding: 2rem

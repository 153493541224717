@use 'common'

.Footer
	font-size: 18px
	padding: 1rem
	margin-top: 3rem
	@media (min-width: 768px)
		padding: 1rem 2rem
		margin-top: 5rem
	@media (min-width: 1024px)
		display: flex

.Support
	max-width: 130px

.Gallery

	@media (min-width: 768px)
		max-width: 75vw
	@media (min-width: 1024px)
		max-width: 100vw

.Auspices
	max-width: 510px
	@media (min-width: 768px)
		margin-left: 1rem

.Mangoweb
	margin-top: 50px
	@media (min-width: 768px)
		margin-top: 0
		display: flex
		justify-content: flex-end
		flex-grow: 1
		padding-left: 1rem

@use 'common'
@use 'sass:math'

.Filters
	padding-top: 1rem
	padding-left: 1rem
	@media (min-width: 768px)
		display: flex
		padding-left: 2rem
		padding-top: 2rem
	@media (min-width: 1024px)
		padding-top: 3rem

.Filter
	+common.openingText
	background: none
	border: none
	cursor: pointer
	margin: 0.5rem
	font-family: inherit
	color: common.$textColor
	&:hover
		text-decoration: underline
	@media (min-width: 768px)
		margin: 0 1rem

	&:nth-child(1)
		margin-left: 0

.isActive
	text-decoration: underline
	font-weight: 900

@use '../vars'

=title
	font-weight: 900
	font-size: 36px
	line-height: 36px
	@media (min-width: 768px)
		font-size: 45px
		line-height: 45px
	@media (min-width: 992px)
		font-size: 55px
		line-height: 55px
	@media (min-width: 1200px)
		font-size: 64px
		line-height: 64px

@use 'common'
@use 'sass:math'

.ArrowLink
	+common.openingText
	text-decoration: none
	color: common.$red
	font-weight: 900
	padding-right: 2rem
	padding-bottom: 2rem
	&:hover
		text-decoration: underline

.Arrow
	display: inline-block
	padding-left: 0.5rem

.ArrowLink + .ArrowLink
	padding-right: 0

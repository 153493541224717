@use 'common'
@use 'sass:math'

.SpecialEvent
	padding: 1rem
	@media (min-width: 768px)
		padding: 2rem

.Title
	+common.pageTitle

.Wrapper
	padding-top: 1rem
	@media (min-width: 768px)
		display: flex

.Subtitle
	+common.title

.Image
	padding-top: 1rem
	@media (min-width: 768px)
		padding-top: 120px

.Box
	@media (min-width: 768px)
		width: math.div(100%, 2)

.OpeningText
	+common.openingText
	padding-top: 2rem
	@media (min-width: 768px)
		padding-left: 2rem
		padding-right: 1rem
		padding-top: 270px
	@media (min-width: 1200px)
		padding-left: 3rem

.ContentEditor
	padding-top: 1rem
	@media (min-width: 768px)
		padding-top: 80px

@use 'common'

.Block
	padding: 0.5rem 0

	&:nth-last-child(1)
		margin-bottom: 3rem

.In
	max-width: common.$container
	margin: auto

	h1
		text-align: center

	h2
		font-weight: 700
		text-align: center
		font-size: 25px
		font-weight: 700
		margin: auto
		max-width: 600px
		@media (min-width: 768px)
			font-size: 52px

	ul
		margin-top: 1rem
		li
			+common.text
			margin: 1rem 1rem 1rem 0

			&::marker
				font-size: 2rem
			&:nth-child(1)
				margin-top: 1.5rem

	ol, p
		+common.text
		margin: 0

		b
			font-weight: 900

	a
		text-decoration: underline

	ol
		li
			font-size: 20px
			line-height: 35px

.viewFooter
	.Block
		padding: 0
		&:nth-child(1)
			margin-top: 0
		&:nth-last-child(1)
			margin-bottom: 0

@use 'common'
@use 'sass:math'

.Layout
	display: grid
	grid-template-columns: 80px 1fr
	@media ( min-width: 768px)
		display: grid
		grid-template-columns: minmax(auto, 250px) 1fr minmax(auto, 250px)

.MobileHeaderButton
	display: flex
	padding: 1rem
	background-color: transparent
	border: none
	width: 100%
	color: #000
	grid-column: 1
	padding-right: 0

.Header
	display: none
	@media (min-width: 768px)
		grid-column: 1
		display: block
		position: sticky
		z-index: 10
		top: 0
		height: fit-content

.MobileHeader
	position: sticky
	top: 0
	z-index: 10
	@media (min-width: 768px)
		display: none

.MobileHeaderContent
	display: none
	position: absolute
	top: 1rem
	left: 0
	width: 100vw

.isActive
	.MobileHeaderContent
		display: block
	.MobileHeaderButton
		visibility: hidden

.Footer
	grid-column: 1 / -1

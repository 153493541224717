@use 'common'

.Header
	display: flex
	flex-direction: column
	font-weight: 900
	font-size: 16px
	line-height: 22px
	margin: 1rem
	width: fit-content
	background-color: rgba(common.$mainColor, 1)
	-webkit-box-shadow: 0px 0px 35px 40px rgba(common.$mainColor, 1)
	-moz-box-shadow: 0px 0px 35px 40px rgba(common.$mainColor, 1)
	box-shadow: 0px 0px 35px 40px rgba(common.$mainColor, 1)

	@media (min-width: 768px)
		margin: 2rem
		font-size: 20px
		line-height: 27px

.LinkWrapper
	margin: 5px 0

.Link
	width: fit-content
	text-decoration: none
	&:hover
		text-decoration: underline
	&.isActive
		text-decoration: underline
		margin-bottom: -2px

@use 'common'
@use 'sass:math'

.Gallery
	display: flex
	flex-wrap: wrap
	align-items: center
	justify-content: center
	@media (min-width: 768px)
		flex-wrap: nowrap

.Image
	margin-right: 1.5rem
	margin-bottom: 1.5rem
	width: 40%
	display: flex
	justify-content: center

@use 'common'

.Event
	padding: 1rem
	@media (min-width: 768px)
		padding: 2rem
.Title
	+common.title
	padding: 1rem

.EventInfoWrapper
	@media (min-width: 768px)
		display: flex
		justify-content: space-between
		margin-top: 2rem

.Image
	margin: 1rem 0
	@media (min-width: 768px)
		margin: 0
		margin-bottom: 2rem

.EventInfo
	@media (min-width: 768px)
		width: 45%
		margin-left: 2rem
	@media (min-width: 992px)
		width: 40%
		margin-left: 3rem
	@media (min-width: 1200px)
		width: 35%

.InfoBox
	margin-bottom: 1.5rem
	@media (min-width: 768px)
		margin-bottom: 2rem

.InfoLabel
	+common.infoNote

.InfoValue
	+common.openingText

.BackButtonWrapper
	display: flex
	align-items: center

.BackButton
	+common.openingText
	color: common.$red

.ContentEditorWrapper
	display: flex

@use 'common'
@use 'sass:math'

.Hero
	font-size: 2vw
	display: flex
	flex-direction: column
	align-items: center
	max-width: 1000px
	margin: auto

.TitleBox
	display: flex
	align-items: flex-end
	font-size: 35vw
	font-size: 15em
	position: relative
	padding-top: .82em
	@media (min-width: 768px)
		font-size: 25vw
	@media (min-width: 1024px)
		font-size: 21vw

.Title
	font-size: 1em
	line-height: 1em * math.div(274, 322)
	font-weight: 900
	letter-spacing: 1em * math.div(24, 322)

.Letter
	position: relative
	z-index: 1
	&:nth-child(1)
		letter-spacing: 1em * math.div(33, 322)
	&:nth-child(2)
		letter-spacing: 1em * math.div(3, 322)
	&:nth-child(1),
	&:nth-child(3)
		z-index: 3

.Flag
	position: absolute
	z-index: 2
	width: 100%
	height: 100%
	display: flex
	justify-content: stretch
	align-items: flex-end
	left: 1em
	left: 1em * math.div(10, 322)

.Supporters
	display: flex
	flex-wrap: wrap
	justify-content: center
	margin-top: 2rem
	width: 100%
	padding: 0 1rem
	min-width: 50vw
	// @media (min-width: 768px)
	// 	display: flex
	// 	flex-wrap: nowrap
	// 	align-items: center
	// 	justify-content: space-around

.Support
	@media (min-width: 768px)
		max-width: 128px
.Auspices
	max-width: 250px
	@media (min-width: 768px)
		padding-left: 1rem

.Text
	font-size: 1.8em
	font-weight: 700
	font-size: clamp(14px, 1.8em, 16px)

@use 'common'
@use 'sass:math'

.TimelinePost
	padding: 0 1.5rem
	position: relative
	padding-bottom: 50px
	@media (min-width: 768px)
		padding: 0
		display: flex
		justify-content: space-between
		padding-bottom: 200px
		&:nth-child(even)
			flex-direction: row-reverse

	.Box
		padding: 1rem 0 1rem 2rem
		@media (min-width: 768px)
			padding: 2rem
			width: math.div(100%, 2)
		@media (min-width: 1200px)
			padding: 2rem 3rem

		.Title
			+common.title
			margin-bottom: 2rem
		.Text
			+common.text
		.AdditionalInfo
			+common.infoNote
	.Date
		+common.infoNote
		transform: rotate(-90deg)
		display: flex
		align-items: center
		color: common.$red
		height: fit-content
		z-index: 1
		background-color: common.$mainColor
		padding: 0 1rem
		white-space: nowrap
		position: absolute
		right: 100%
		top: -1rem
		transform-origin: right center

	.Border
		border-right: 3px solid common.$red
		height: 100%
		left: 1.5rem
		position: absolute
		z-index: 0
		top: 0
		@media (min-width: 768px)
			left: 50%

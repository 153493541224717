@use 'common'
@use 'sass:math'
.FrontPage
	//

.EventsTitle
	+common.title
	padding: 0 1rem
	margin: 6rem 0px 0
	@media (min-width: 768px)
		padding: 0 2rem
		margin: 6rem 0px 0

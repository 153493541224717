@use 'common'
@use 'sass:math'

.Page
	padding: 1rem
	@media (min-width: 768px)
		padding: 2rem

.Title
	+common.pageTitle

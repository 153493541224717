@use 'common'
@use 'sass:math'

.ImageBox
	max-width: 668px
	margin: 2rem 0
	@media (min-width: 768px)
		width: 50%

.Image
	position: relative
	width: 100%
	height: 100%
	padding-top: 100% * math.div(588, 882)

.ImageInfo
	+common.infoNote
	font-style: italic
	font-weight: normal

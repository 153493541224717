@use 'common'
@use 'sass:math'

.TextBoxes
	margin-top: 60px
	@media (min-width: 768px)
		display: flex
		margin-top: 100px

.TextBox
	display: flex
	flex-direction: column
	padding: 0 1rem
	@media (min-width: 768px)
		width: math.div(100%, 2)
		padding: 0 2rem

.Links
	display: flex
	flex-wrap: wrap

.Subtitle
	+common.title

.OpeningText
	+common.openingText
	margin: 2rem 0
	@media (min-width: 768px)
		margin: 0
		margin-bottom: 3rem

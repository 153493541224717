@use 'common'

.SourcePage
	padding: 1rem
	@media (min-width: 768px)
		padding: 2rem

.Title
	+common.pageTitle

.Links
	//

.LinkWrapper
	margin: 3rem 0

.LinkNote
	+common.infoNote

@use 'sass:math'

.Main
	grid-column: 1 / -1

.isFill
	grid-column: 2 / -1
	@media (min-width: 768px)
		grid-column: 2 / -1

.heroFill:not(.isFill)
	grid-column: 1 / -1
	@media (min-width: 1024px)
		grid-column: auto
